import React, { useEffect, useState } from "react";
import Logo from "../../../assets/customer/logo.png";
import { FaBars, FaChartBar, FaRegUser, FaBell, FaCheck } from "react-icons/fa";
import {
  FaArrowRightFromBracket,
  FaBox,
  FaCirclePlus,
  FaRegCircle,
  FaRegFile,
  FaUser,
  FaUserGroup,
  FaWrench,
  FaX,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import ProfilePic from "../../../assets/user-profile.jpg";
import axios from "axios";
import moment from "moment";

const Header = ({ handleShowModal }) => {
  const token = localStorage.getItem("adminToken");
  const [offCanvas, setOffCanvas] = useState(false);
  const handleOffCanvas = () => {
    setOffCanvas(!offCanvas);
  };
  const adminSidebar = useSelector((state) => state.sidebarSlice.adminSidebar);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };
  const [showNotifications, setShowNotifications] = useState(false);
  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const [notifications, setNotifications] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(null);

  const fetchNotifications = () => {
    axios({
      method: "GET",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/get-notification",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        setNotifications(data);
        setNotificationsCount(res?.data?.unreadCount);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchNotifications();
  }, [dispatch]);

  const handleReadNotifications = (id) => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/update-read-notification",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
      },
    })
      .then((res) => {
        fetchNotifications();
      })
      .catch((err) => {});
  };
  return (
    <div className="relative">
      <div className="hidden w-full  py-[10px] xl:flex gap-[20px] justify-end px-[30px] items-center">
        <FaUser
          onClick={() => navigate("/admin/settings")}
          className="cursor-pointer"
        />
        {showNotifications === true ? (
          <FaX
            className="cursor-pointer"
            onClick={() => handleShowNotifications()}
          />
        ) : (
          <div className=" relative">
            <div className="bottom-[10px] right-[6px] absolute h-[15px] w-[15px] rounded-[50%] bg-green-500 text-center flex justify-center items-center text-white text-[10px]">
              {notificationsCount}
            </div>
            <FaBell
              onClick={() => {
                handleShowNotifications();
              }}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      {showNotifications === true ? (
        <div className="w-full sm:w-[50%] xl:w-[30%] z-[99]  bg-[#fff] rounded-[20px] shadow-xl flex flex-col absolute top-[100px] sm:top-[70px] sm:right-[20px]">
          <div className="w-full border-b text-[15px] border-gray-300 pb-[7px] flex justify-between items-center">
            <h3 className="font-medium p-[10px]">Notifications</h3>
          </div>
          <div className="flex items-start justify-between w-full p-[7px]">
            {notifications?.length !== 0 ? (
              <ul className="w-full h-[350px] overflow-y-scroll hide-scrollbar list-disc text-[12px] flex flex-col gap-[3px]">
                {notifications?.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleReadNotifications(item?.id)}
                      key={index}
                      className={`w-full flex items-center justify-between px-[20px] py-[10px] rounded-[10px] ${
                        item?.read !== true ? "bg-green-100" : null
                      }`}
                    >
                      <li className="cursor-pointer border-b border-gray-200 py-[5px] w-full">
                        <p className="font-normal">{item?.text}</p>
                        <h3 className="font-semibold text-[10px] italic text-[#b2b2b2]">
                          {moment(item?.createdAt)?.fromNow()}
                        </h3>
                      </li>
                    </div>
                  );
                })}
              </ul>
            ) : (
              <div className="w-full flex justify-center items-center">
                <p>No Notifications yet!</p>
              </div>
            )}
          </div>
        </div>
      ) : null}
      <div className="xl:hidden w-full p-[12px] items-center flex justify-between gap-[12px]">
        <img
          loading="lazy"
          src={Logo}
          alt="logo"
          className="w-[55%] sm:w-[25%]"
        />
        <div className="flex items-center gap-[20px]">
          {showNotifications === true ? (
            <FaX
              className="cursor-pointer"
              onClick={() => handleShowNotifications()}
            />
          ) : (
            <FaBell
              onClick={() => {
                handleShowNotifications();
              }}
              className="cursor-pointer"
            />
          )}

          <div>
            {offCanvas === true ? (
              <FaX onClick={handleOffCanvas} />
            ) : (
              <FaBars onClick={handleOffCanvas} />
            )}
          </div>
        </div>

        {offCanvas === true ? (
          <div className="fixed z-[99] top-0 left-0 w-full h-full text-[12px] flex flex-col p-[20px] items-center gap-[12px] justify-between bg-white">
            <div className="w-full   flex flex-col gap-[25px] items-center">
              <div className="w-full flex items-center justify-between">
                <Link to={"/admin/"} className=" flex justify-center">
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                    className=" cursor-pointer "
                  />
                </Link>
                <FaX onClick={handleOffCanvas} />
              </div>
              <div className="flex flex-col w-full gap-[10px]">
                <Link
                  onClick={() => dispatch(setAdminSidebar("dashboard"))}
                  to={"/admin/dashboard"}
                  className={`w-full flex ${
                    adminSidebar === "dashboard"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaChartBar />
                  Dashboard
                </Link>
                <button
                  onClick={() => {
                    dispatch(setAdminSidebar("create-order"));
                    handleShowModal();
                  }}
                  className={`w-full flex ${
                    adminSidebar === "create-order"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaCirclePlus />
                  Create Order
                </button>

                <Link
                  onClick={() => dispatch(setAdminSidebar("user-info"))}
                  to={"/admin/user-list"}
                  className={`w-full flex ${
                    adminSidebar === "user-info"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaRegUser />
                  User Info
                </Link>
                <Link
                  onClick={() => dispatch(setAdminSidebar("editor-info"))}
                  to={"/admin/editor-list"}
                  className={`w-full flex ${
                    adminSidebar === "editor-info"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaUserGroup />
                  Editor Info
                </Link>
                <Link
                  onClick={() => dispatch(setAdminSidebar("order-list"))}
                  to={"/admin/order-list"}
                  className={`w-full flex ${
                    adminSidebar === "order-list"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaRegFile />
                  Order List
                </Link>
                <Link
                  onClick={() => dispatch(setAdminSidebar("settings"))}
                  to={"/admin/settings"}
                  className={`w-full flex ${
                    adminSidebar === "settings"
                      ? "bg-[#22d87c] text-white"
                      : "text-black"
                  } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
                >
                  <FaWrench />
                  Account
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full flex  font-semibold bg-[#22d87c] text-white items-center gap-[7px] px-[20px] py-[10px] rounded-[7px]"
                >
                  <FaArrowRightFromBracket />
                  <h3>Logout</h3>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
