import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/customer/logo.png";
import {
  FaArrowRightFromBracket,
  FaBox,
  FaCirclePlus,
  FaFileShield,
  FaRegCircle,
  FaRegFile,
  FaRegUser,
  FaUserGroup,
  FaWpexplorer,
  FaWrench,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";
import { FaChartBar } from "react-icons/fa";
import axios from "axios";
const Sidebar = ({ showModal, setShowModal, handleShowModal }) => {
  const adminSidebar = useSelector((state) => state.sidebarSlice.adminSidebar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/admin/login");
  };

  return (
    <div className="w-full h-full text-[12px] flex flex-col p-[20px] items-center gap-[20px] justify-between bg-white">
      <div className="w-full   flex flex-col gap-[25px] items-center">
        <div className="w-full">
          <Link to={"/admin"}>
            <img loading="lazy" src={Logo} alt="logo" className="w-full cursor-pointer " />
          </Link>
        </div>
        <div className="flex flex-col w-full gap-[10px]">
          <Link
            onClick={() => dispatch(setAdminSidebar("dashboard"))}
            to={"/admin/dashboard"}
            className={`w-full flex ${
              adminSidebar === "dashboard"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaChartBar />
            Dashboard
          </Link>

          {/* <Link
            onClick={() => dispatch(setAdminSidebar("file-explorer"))}
            to={"/admin/file-explorer"}
            className={`w-full flex ${
              adminSidebar === "file-explorer"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaFileShield />
            File Explorer
          </Link> */}
          <button
            onClick={() => {
              dispatch(setAdminSidebar("create-order"));
              handleShowModal();
            }}
            className={`w-full flex ${
              adminSidebar === "create-order"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaCirclePlus />
            Create Order
          </button>
          {/* <Link
            onClick={() => dispatch(setAdminSidebar("order-status"))}
            to={"/admin/order-status"}
            className={`w-full flex ${
              adminSidebar === "order-status"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegCircle />
            Order Status
          </Link> */}
          <Link
            onClick={() => dispatch(setAdminSidebar("user-info"))}
            to={"/admin/user-list"}
            className={`w-full flex ${
              adminSidebar === "user-info"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegUser />
            User Info
          </Link>
          <Link
            onClick={() => dispatch(setAdminSidebar("editor-info"))}
            to={"/admin/editor-list"}
            className={`w-full flex ${
              adminSidebar === "editor-info"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaUserGroup />
            Editor Info
          </Link>
          {/* <Link
            onClick={() => dispatch(setAdminSidebar("order-detail"))}
            to={"/order-detail"}
            className={`w-full flex ${
              adminSidebar === "order-detail"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegFile />
            Order Detail
          </Link> */}
          {/* <Link
            onClick={() => dispatch(setAdminSidebar("delivery"))}
            to={"/admin/delivery"}
            className={`w-full flex ${
              adminSidebar === "delivery"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaBox />
            Delivery
          </Link> */}
          <Link
            onClick={() => dispatch(setAdminSidebar("order-list"))}
            to={"/admin/order-list"}
            className={`w-full flex ${
              adminSidebar === "order-list"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaRegFile />
            Order List
          </Link>
          <Link
            onClick={() => dispatch(setAdminSidebar("settings"))}
            to={"/admin/settings"}
            className={`w-full flex ${
              adminSidebar === "settings"
                ? "bg-[#22d87c] text-white"
                : "text-black"
            } font-semibold items-center gap-[7px] p-[10px] rounded-[7px]`}
          >
            <FaWrench />
            Account
          </Link>
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={handleLogout}
          className=" flex  font-semibold bg-[#22d87c] text-white items-center gap-[7px] px-[20px] py-[10px] rounded-[7px]"
        >
          <FaArrowRightFromBracket />
          <h3>Logout</h3>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
