import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/layout";
import {
  FaAngleDown,
  FaCheck,
  FaCircleCheck,
  FaClock,
  FaListCheck,
  FaPencil,
  FaPlus,
  FaRegCircle,
  FaRegFile,
  FaRightLong,
  FaRocket,
  FaUpload,
} from "react-icons/fa6";
import ProfilePic from "../../../assets/admin/profile1.jpg";
import Img from "../../../assets/admin/img.webp";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerSidebar } from "../../../redux/reducer/sidebarSlice";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  setClientInformation,
  setFuneralDetails,
  setNotes,
  setOrderDetails,
  setPushedNotes,
  setSelectedImg,
  setSelectedMusic,
  setSelectedVideo,
  setTimeLine,
  setType,
  setUploadPhoto,
} from "../../../redux/reducer/newOrderCustomerSlice";
import moment from "moment";
import CustomerOrderDetail from "./order-detail";
import { FaRegEdit, FaUserEdit } from "react-icons/fa";
const CustomerDeliveryPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("customerToken");
  const timeline = useSelector((state) => state.newOrderCustomerSlice.timeLine);
  const order = useSelector((state) => state.newOrderCustomerSlice);
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(order?.orderDetails.requestedDate)
  );

  const [pricingObj, setPricingObj] = useState({
    imagePrice: 0,
    musicPrice: 0,
    totalPrice: 0,
    videoPrice: 0,
    sceneryPrice: 0,
    coverImagePrice: 0,
  });

  const items = [
    { name: "Image", key: "imagePrice" },
    { name: "Music", key: "musicPrice" },
    { name: "Video", key: "videoPrice" },
    { name: "Scenery", key: "sceneryPrice" },
    { name: "Cover Image", key: "coverImagePrice" },
  ];

  const getPricing = async () => {
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/get-order/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setPricingObj(res.data.Data.pricingObj);
    });
  };
  const [note, setNote] = useState("");
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(order?.orderDetails.requestedDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [order?.orderDetails.requestedDate]);

  function calculateTimeLeft(target) {
    if (!target) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const now = moment();
    const endOfTargetDate = moment(target).endOf("day");
    const duration = moment.duration(endOfTargetDate.diff(now));

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }
  const sumbitNote = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/add-notes`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
        note: note,
        type: "customer",
      },
    })
      .then((res) => {
        dispatch(
          setPushedNotes({
            id: id,
            note: note,
            type: "customer",
          })
        );
        setNote("");
      })
      .catch((err) => {});
  };

  const getNotes = async () => {
    await axios({
      url: `https://funeral-api.hitoritech.co.uk/api/v1/get-notes/${id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch(setNotes(res.data.Data));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getNotes();
    axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/get-order/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        // dispatch(setVideos(data?.totalscenery || []));
        // dispatch(setImg(data?.totalcoverphotos || []));
        dispatch(
          setClientInformation({
            businessName: data?.businessName,
            contactName: data?.contactName,
            email: data?.email,
            phone: data?.phone,
            address: data?.address,
            city: data?.city,
            state: data?.state,
            postalCode: data?.postalCode,
          })
        );
        dispatch(
          setOrderDetails({
            orderCreationDate: data?.orderCreationDate,
            requestedDate: data?.requestedDate,
            orderNumber: data?.orderNumber,
            styleOption: data?.styleOption,
            videoType: data?.videoType,
            productionType: data?.productionType,
          })
        );
        dispatch(
          setFuneralDetails({
            subjectName: data?.subjectName,
            dateOfBirth: data?.dateOfBirth,
            dateOfDeath: data?.dateOfDeath,
            funeralDate: data?.funeralDate,
            funeralTime: data?.funeralTime,
          })
        );
        dispatch(setUploadPhoto(data?.photo || []));
        dispatch(setType(data?.type));
        dispatch(setTimeLine(data?.timeline));
        dispatch(
          setSelectedImg(
            data?.coverPhotos?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
        dispatch(
          setSelectedVideo(
            data?.scenery?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
        dispatch(
          setSelectedMusic(
            data?.music?.map((item) => {
              return JSON.parse(item);
            })
          )
        );
      })
      .catch((err) => {});

    return () => {
      dispatch(
        setClientInformation({
          businessName: "",
          contactName: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
        })
      );
      dispatch(
        setOrderDetails({
          orderCreationDate: "",
          requestedDate: "",
          orderNumber: "",
          styleOption: "",
          videoType: "",
          productionType: "",
        })
      );
      dispatch(
        setFuneralDetails({
          subjectName: "",
          dateOfBirth: "",
          dateOfDeath: "",
          funeralDate: "",
          funeralTime: "",
        })
      );
      dispatch(setSelectedVideo([]));
      dispatch(setSelectedMusic([]));
    };
  }, []);

  useEffect(() => {
    dispatch(setCustomerSidebar("delivery"));
  }, []);
  const scroll = useRef();
  useEffect(() => {
    scroll.current?.scrollIntoView({ behaviour: "smooth" });
  }, [order.notes]);

  const [tabs, setTabs] = useState(1);
  return (
    <Layout>
      <div className="w-full flex flex-col p-[20px] text-[14px] gap-[20px] h-full overflow-y-scroll hide-scrollbar bg-[#f5f5f5]">
        <div className="w-full gap-[20px] flex font-semibold  items-center border-b border-gray-200">
          <h3
            onClick={() => setTabs(1)}
            className="text-[#969696] cursor-pointer pb-[4px] hover:text-[#22d87c] border-b border-transparent hover:border-[#22d87c] "
          >
            ACTIVITY
          </h3>
          <h3
            onClick={() => {
              setTabs(2);
              getPricing();
            }}
            className="text-[#969696] cursor-pointer pb-[4px] hover:text-[#22d87c] border-b border-transparent hover:border-[#22d87c] "
          >
            DETAILS
          </h3>
          <h3
            onClick={() => setTabs(3)}
            className="text-[#969696] cursor-pointer pb-[4px] hover:text-[#22d87c] border-b border-transparent hover:border-[#22d87c] "
          >
            REQUIREMENTS
          </h3>
        </div>
        {tabs === 1 ? (
          <div className="w-full flex flex-col xl:flex-row gap-[40px]">
            <div className="w-full xl:w-[70%] flex flex-col gap-[20px]">
              <div className="w-full p-[20px] xl:p-[35px] border-l-[5px] rounded-[7px] border-[#22d87c] flex flex-col gap-[4px] bg-white shadow-lg">
                <h1 className="text-[22px] font-bold text-[#535353]">
                  Order Started
                </h1>
                <p className="text-[16px] text-[#696969] font-semibold">
                  sent all the information you need so you can start working on
                  this order. You got this!
                </p>
              </div>
              <div className="w-full  py-[30px] rounded-[7px] flex flex-col items-start  bg-white shadow-lg">
                <div className="pl-[50px] pr-[20px] rounded-r-[30px] py-[7px] text-[12px] bg-[#f2f2f2] text-[#838383] font-semibold">
                  {moment(timeline[0]?.time).fromNow()}
                </div>

                {timeline?.map((item) => {
                  return (
                    <div className="w-full flex flex-col gap-[12px] items-center border-b border-gray-200 px-[20px] py-[10px]">
                      <div className="w-full flex text-[11px] sm:text-[12px] flex-wrap  items-end gap-[7px] sm:gap-[12px]">
                        {item.status === "Requested-Date" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                            <FaClock />
                          </div>
                        ) : null}
                        {item.status === "Published" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                            <FaUpload />
                          </div>
                        ) : null}
                        {item.status === "Draft" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                            {" "}
                            <FaRocket />
                          </div>
                        ) : null}
                        {item.status === "Editing" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                            <FaPencil />
                          </div>
                        ) : null}
                        {item.status === "Completed" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                            <FaCheck />
                          </div>
                        ) : null}

                        {item.status === "Re-edit-1" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                            <FaRegEdit />
                          </div>
                        ) : null}

                        {item.status === "Re-edit-2" ? (
                          <div className="w-[40px] text-[17px] h-[40px] bg-[#ddf2fe] text-[#86a2be] rounded-[50px] flex items-center justify-center">
                            <FaUserEdit />
                          </div>
                        ) : null}
                        <p className="text-[#696a6c] font-semibold">
                          {item.message}
                        </p>
                        <p className="italic text-[#9f9f9f] text-[11px] font-semibold">
                          {moment(item.time).fromNow()}
                        </p>
                        {item?.status === "Completed" ? (
                          <div className="w-full">
                            {item?.proof?.path?.length !== 0 ? (
                              <div className="w-full flex items-center justify-start flex-wrap gap-[20px]">
                                {item?.proof?.path?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="w-[25%] h-[200px]"
                                    >
                                      <img
                                        loading="lazy"
                                        src={item}
                                        alt="item"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                  );
                                })}
                                <p className="w-full">{item?.proof?.text}</p>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

                <div className="w-full flex flex-col gap-[12px] h-[500px] overflow-y-scroll hide-scrollbar">
                  {order.notes.map((item, index) => {
                    return (
                      <div
                        key={index}
                        ref={index === order.notes.length - 1 ? scroll : null}
                        className="w-full  flex gap-[12px] items-center border-b border-gray-200 px-[20px] py-[10px]"
                      >
                        <div className="w-[40px] text-[17px] h-[40px] bg-[#daf6e9] text-[#65a68b] rounded-[50px] flex items-center justify-center">
                          <img
                            loading="lazy"
                            src={ProfilePic}
                            alt="img"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="w-full flex flex-col gap-[4px]">
                          <div className="flex text-[11px] sm:text-[12px] flex-wrap sm:flex-nowrap items-end gap-[7px] sm:gap-[12px]">
                            <p className="text-[#696a6c] font-semibold">
                              {item?.type === "customer"
                                ? "You Sent a Note"
                                : "Admin Sent a Note"}
                            </p>
                            <p className="italic text-[#9f9f9f] text-[11px] font-semibold">
                              {moment().fromNow(item.time)}
                            </p>
                          </div>
                          <p className="text-[#696a6c] text-[11px] sm:text-[12px] font-semibold">
                            {item.note}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="w-full flex justify-center items-center">
                  <input
                    type="text"
                    className="bg-[#f5f5f5] w-[80%] h-[40px] rounded-lg mt-5 pl-6"
                    placeholder="Type Your Note"
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                  <div className="flex justify-center items-center w-[15%]">
                    <button
                      className="bg-green-600 p-3 rounded-[50%] mt-5 text-white"
                      onClick={sumbitNote}
                    >
                      <FaRightLong />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-[30%] flex flex-col gap-[20px]">
              {order?.type === "Published" ? (
                <div className="w-full p-[20px]  rounded-[7px]  flex flex-col gap-[12px] bg-white shadow-lg">
                  <h2 className=" font-bold text-[#535353]">
                    Time left to deliver
                  </h2>
                  <div className="w-full flex justify-center xl:justify-between gap-[15px] text-center ">
                    <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                      <p className="font-bold text-[16px]">{timeLeft?.days}</p>
                      <p className="text-sm text-[#a9a9a9] font-semibold ">
                        Days
                      </p>
                    </div>
                    <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                      <p className="font-bold text-[16px]">{timeLeft?.hours}</p>
                      <p className="text-sm text-[#a9a9a9] font-semibold ">
                        Hours
                      </p>
                    </div>
                    <div className="flex flex-col items-center  border-r border-gray-300 pr-4">
                      <p className="font-bold text-[16px]">
                        {timeLeft?.minutes}
                      </p>
                      <p className="text-sm text-[#a9a9a9] font-semibold ">
                        Minutes
                      </p>
                    </div>
                    <div className="flex flex-col items-center ">
                      <p className="font-bold text-[16px]">
                        {timeLeft?.seconds}
                      </p>
                      <p className="text-sm text-[#a9a9a9] font-semibold ">
                        Seconds
                      </p>
                    </div>
                  </div>
                  {/* <button className="bg-[#1dbf73] p-[10px] rounded-[4px] text-white font-semibold">
                Deliver Now
              </button> */}
                </div>
              ) : null}

              <div className="w-full   rounded-[7px]  flex flex-col  bg-white shadow-lg">
                <div className="w-full border-b border-gray-200 px-[20px] py-[15px] flex flex-col gap-[12px]">
                  <div className="w-full flex justify-between items-center">
                    <h2 className=" font-bold text-[#535353]">Order Detail</h2>
                    <FaAngleDown />
                  </div>

                  <div className="w-full flex items-center gap-[7px]">
                    {order?.photo?.length !== 0 && order?.photo !== null ? (
                      <div className="w-[150px] h-[100px]">
                        <img
                          loading="lazy"
                          src={order?.uploadPhotos[0]?.url}
                          alt="img"
                          className="w-full h-full object-contain"
                        />
                      </div>
                    ) : null}
                    <div className="flex flex-col items-start gap-[7px]">
                      {order?.funeralDetails.subjectName === "" ? (
                        <p>No Subject Given</p>
                      ) : (
                        <p>{order?.funeralDetails.subjectName} </p>
                      )}
                      {order?.type !== null ? (
                        <div className="px-[10px] py-[5px] rounded-[4px] text-white text-[9px] font-semibold bg-[#105d77]">
                          {order?.type}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {localStorage.getItem("customerToken") ? null : (
                <div className="w-full p-[20px]  rounded-[7px]  flex flex-col gap-[12px] bg-white shadow-lg">
                  <div className="w-full flex justify-between items-center">
                    <h2 className=" font-bold text-[#535353]">Private Note</h2>
                    <p className="font-semibold cursor-pointer text-[#1dbf73] flex items-center gap-[7px]">
                      <FaPlus /> Add Note
                    </p>
                  </div>
                  <p className="text-[#c8c8c8] font-semibold">
                    Only visible to you
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {tabs === 2 ? (
          <div className="container mx-auto p-4">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Item</th>
                  <th className="py-2 px-4 border-b">Price</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.key}>
                    <td className="py-2 px-4 border-b">{item.name}</td>
                    <td className="py-2 px-4 border-b">
                      {pricingObj[item.key]}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="py-2 px-4 border-b font-bold">Total Price</td>
                  <td className="py-2 px-4 border-b font-bold">
                    {pricingObj.totalPrice}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
        {tabs === 3 ? <CustomerOrderDetail deliveryPageOrderId={id} /> : null}
      </div>
    </Layout>
  );
};

export default CustomerDeliveryPage;
