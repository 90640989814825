import React, { useRef, useState, useEffect } from "react";
import { FaScissors, FaTrash, FaX } from "react-icons/fa6";
import Img from "../../../assets/customer/upload.png";
import ReactCrop from "react-image-crop";
import { canvasPreview } from "../common/cropImage";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setTotalPricing,
  setUploadPricing,
} from "../../../redux/reducer/pricingSlice";
import { setFiles } from "../../../redux/reducer/imageUploadSlice";
import axios from "axios";
import {
  pushUploadPhoto,
  setImg,
  setUploadPhoto,
  updateFileComment,
  updateFileUrl,
} from "../../../redux/reducer/newOrderCustomerSlice";
import Spinner from "../../../utils/spinner";
import moment from "moment";

const Step3 = ({
  steps,
  setSteps,
  tabs,
  setTabs,
  orderId,
  changesOccur,
  setChangesOccur,
}) => {
  const files = useSelector((state) => state.imageUploadSlice.files) || [];
  const [showItemIndex, setShowItemIndex] = useState(false);
  const handleShowItemIndex = (index) => {
    setShowItemIndex(index === showItemIndex ? null : index);
  };
  const uploadPhoto = useSelector(
    (state) => state.newOrderCustomerSlice.uploadPhotos
  );
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [fileDragging, setFileDragging] = useState(null);
  const [fileDropping, setFileDropping] = useState(null);
  const [rotations, setRotations] = useState([]);
  const imgRefs = useRef([]);
  const [heights, setHeights] = useState([]);
  const [widths, setWidths] = useState([]);
  const [crops, setCrops] = useState([]);
  const [completeCrops, setCompleteCrops] = useState([]);
  const [scales, setScales] = useState([]);
  const [output, setOutput] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const pricing = useSelector((state) => state.pricingSlice.pricing);
  const imageUrl = files.map((item) => item.url);
  const dispatch = useDispatch();
  const [isImgUploaded, setImgUploaded] = useState(false);
  const totalPricing = useSelector((state) => state.pricingSlice.totalPricing);

  const humanFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };

  const orderCreatedDate = useSelector(
    (state) => state.newOrderCustomerSlice.orderDetails.orderCreationDate
  );
  const orderCreatedyear = moment(orderCreatedDate).year();
  const uploadFormData = new FormData();
  uploadFormData.append(
    "folderPath",
    `${moment(orderCreatedDate).year()}/${moment(orderCreatedDate).format(
      "MMMM"
    )}/${orderId}/assets`
  );

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const allowedFiles = newFiles.filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg"
    );

    const combinedFiles = [
      ...uploadPhoto,
      ...allowedFiles.map((file) => ({
        url: loadFile(file),
        originalFile: file,
        comment: "",
      })),
    ];

    setRotations([...rotations, ...Array(allowedFiles.length).fill(0)]);
    setCrops([...crops, ...Array(allowedFiles.length).fill(null)]);
    setCompleteCrops([
      ...completeCrops,
      ...Array(allowedFiles.length).fill(null),
    ]);
    setScales([...scales, ...Array(allowedFiles.length).fill(1)]);

    setImgUploaded(true);
    combinedFiles.forEach((fileObj) => {
      uploadFormData.append(`files`, fileObj.originalFile);
    });
    handleSubmit();
  };

  const loadFile = (file) => {
    const blobUrl = URL.createObjectURL(file);
    return blobUrl;
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...uploadPhoto];
    newFiles.splice(index, 1);
    dispatch(setUploadPhoto(newFiles));

    const newRotations = [...rotations];
    newRotations.splice(index, 1);
    setRotations(newRotations);

    const newCrops = [...crops];
    newCrops.splice(index, 1);
    setCrops(newCrops);

    const newCompleteCrops = [...completeCrops];
    newCompleteCrops.splice(index, 1);
    setCompleteCrops(newCompleteCrops);

    const newScales = [...scales];
    newScales.splice(index, 1);
    setScales(newScales);

    const additionalCost = -10;
    setChangesOccur(true);
  };

  const handleDragEnter = (e, index) => {
    setFileDropping(index);
    setChangesOccur(true);
  };

  const handleDragStart = (e, index) => {
    setFileDragging(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (fileDragging !== null && fileDropping !== null) {
      const newFiles = [...uploadPhoto];
      const draggedFile = newFiles[fileDragging];
      newFiles.splice(fileDragging, 1);
      newFiles.splice(fileDropping, 0, draggedFile);
      dispatch(setUploadPhoto(newFiles));
      setFileDragging(null);
      setFileDropping(null);
    } else {
      const newFiles = Array.from(e.dataTransfer.files);
      const allowedFiles = newFiles.filter(
        (file) => file.type === "image/png" || file.type === "image/jpeg"
      );

      const combinedFiles = [
        ...uploadPhoto,
        ...allowedFiles.map((file) => ({
          url: loadFile(file),
          originalFile: file,
          comment: "",
        })),
      ];
      combinedFiles.forEach((fileObj) => {
        uploadFormData.append(`files`, fileObj.originalFile);
      });
      handleSubmit();

      setRotations([...rotations, ...Array(allowedFiles.length).fill(0)]);
      setCrops([...crops, ...Array(allowedFiles.length).fill(null)]);
      setCompleteCrops([
        ...completeCrops,
        ...Array(allowedFiles.length).fill(null),
      ]);
      setScales([...scales, ...Array(allowedFiles.length).fill(1)]);

      setChangesOccur(true);
      setImgUploaded(true);
    }
  };

  const handleCommentChange = (index, newComment) => {
    dispatch(updateFileComment({ index, comment: newComment }));
    setChangesOccur(true);
  };

  const onImageLoad = (e, index) => {
    const newHeights = [...heights];
    const newWidths = [...widths];
    newHeights[index] = e.currentTarget.height;
    newWidths[index] = e.currentTarget.width;
    setHeights(newHeights);
    setWidths(newWidths);
    const newCompleteCrops = [...completeCrops];
    newCompleteCrops[index] = {
      x: 0,
      y: 0,
      height: e.currentTarget.height,
      width: e.currentTarget.width,
      unit: "px",
    };
    setCompleteCrops(newCompleteCrops);
  };

  const rotateRight = (index) => {
    const newRotations = [...rotations];
    let newRotation = newRotations[index] + 90;
    if (newRotation >= 360) {
      newRotation = 0;
    }
    newRotations[index] = newRotation;
    setRotations(newRotations);
  };

  const download = async (index) => {
    const croppedImageBase64 = await canvasPreview(
      imgRefs.current[index],
      completeCrops[index],
      scales[index],
      rotations[index]
    );
    const link = document.createElement("a");
    link.href = croppedImageBase64;
    link.download = "cropped-image.jpg";
    link.click();
  };

  const previewBlob = async (index) => {
    const blobUrl = await canvasPreview(
      imgRefs.current[index],
      completeCrops[index],
      scales[index],
      rotations[index]
    );
    setOutput(blobUrl);
  };

  const [blobFile, setBlobFile] = useState(null);
  const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    setBlobFile(file);
  };

  const [originalFileName, setOriginalFileName] = useState("");

  const handleCropClick = (index) => {
    setSelectedFileIndex(index);
    setShowCropModal(true);
  };

  const closeCropModal = () => {
    setShowCropModal(false);
    setSelectedFileIndex(null);
  };

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const formData = new FormData();
  formData.append(
    "folderPath",
    `${moment(orderCreatedDate).year()}/${moment(orderCreatedDate).format(
      "MMMM"
    )}/${orderId}/assets`
  );
  const handleCropSubmit = async () => {
    const croppedImageBase64 = await canvasPreview(
      imgRefs.current[selectedFileIndex],
      completeCrops[selectedFileIndex],
      scales[selectedFileIndex],
      rotations[selectedFileIndex]
    );

    const updatedFiles = [...uploadPhoto];
    const updatedFile = {
      ...updatedFiles[selectedFileIndex],
      url: croppedImageBase64,
    };
    updatedFiles[selectedFileIndex] = updatedFile;
    const splitFileName = uploadPhoto[selectedFileIndex].url.split(
      // "https://project-p1.nyc3.digitaloceanspaces.com/"
      "/assets/"
    );
    formData.append(
      `files`,
      base64ToFile(croppedImageBase64, `cropped-${splitFileName[1]}`)
    );

    handleUpdateImage(updatedFiles);
    setOutput(null);
    setShowCropModal(false);
    setSelectedFileIndex(null);
    setChangesOccur(true);
  };

  const objectData = uploadPhoto?.map((item) => {
    return item?.originalFile;
  });

  const token = localStorage.getItem("customerToken");

  const img = useSelector((state) => state.newOrderCustomerSlice.img);

  const [urls, setUrls] = useState([]);

  const [tempPhotos, setTempPhotos] = useState(uploadPhoto);

  const handleSubmit = () => {
    setIsFileUploading(true);
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: uploadFormData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item, index) => {
          const cleanUrl = item.replace(/\s+(\d{4})/, "/$1");
          return {
            url: cleanUrl,
            comment: "",
          };
        });

        dispatch(pushUploadPhoto(formattedArray));
        setImgUploaded(false);
        setChangesOccur(true);
        setIsFileUploading(false);
      })
      .catch((err) => {
        setChangesOccur(false);
        setIsFileUploading(false);
      });
  };

  const handleManageLinks = (link) => {
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/update-total-order-photos",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        photoObj: [...img, ...link],
        orderId: orderId,
      },
    })
      .then((res) => {})
      .catch((err) => {});
  };

  // useEffect(() => {
  //   dispatch(setFiles(uploadPhoto || []));
  // }, [uploadPhoto]);

  const handleUpdateImage = () => {
    setIsFileUploading(true);
    uploadPhoto.forEach((fileObj) => {
      formData.append(`files`, fileObj[selectedFileIndex]);
    });
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item, index) => {
          const cleanUrl = item.replace(/\s+(\d{4})/, "/$1");
          return {
            index: selectedFileIndex,
            url: cleanUrl,
            comment: "",
          };
        });
        dispatch(updateFileUrl(formattedArray[0]));
        setImgUploaded(false);
        setChangesOccur(true);
        setIsFileUploading(false);
      })
      .catch((err) => {
        setChangesOccur(false);
        setIsFileUploading(false);
      });
  };

  return (
    <div className="w-full flex flex-col items-center gap-[20px]">
      <div className="w-full flex justify-end mt-[30px] items-center gap-[20px]">
        <button onClick={() => setTabs(false)}>
          <FaX />
        </button>
      </div>
      <div className="flex flex-col gap-[7px]">
        <div className="flex flex-col items-center gap-[20px]">
          <h1 className="font-extrabold text-[25px] text-center">
            Upload Image
          </h1>
        </div>
        {/* <p className="text-center">
          Would You like to be contacted when the order is complete:
        </p> */}
      </div>
      <div className="w-full rounded mx-auto">
        <div className="relative flex flex-col text-gray-400 rounded">
          <div className="relative flex flex-col text-gray-400 rounded">
            <div
              className="relative h-[400px] border-dashed flex flex-col justify-center text-gray-400 border border-[#22d87c] rounded cursor-pointer"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <input
                type="file"
                accept="image/png, image/jpeg"
                multiple
                className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                onChange={handleFileChange}
              />
              <div className="flex flex-col items-center justify-center py-10 text-center">
                <img loading="lazy" src={Img} alt="img" className="w-[300px]" />
                <h3 className="text-center text-black font-bold text-[20px]">
                  Drop Files Here
                  <br />
                  Or
                </h3>
                <button className="px-[20px] py-[7px] bg-[#414143] text-white rounded-[7px]">
                  Upload here
                </button>
              </div>
            </div>
          </div>
          {uploadPhoto.length > 0 && (
            <div className="w-full flex mt-[30px] flex-wrap items-center  justify-center gap-[20px]">
              {uploadPhoto.map((file, index) => (
                <div
                  key={index}
                  className="w-full sm:w-[45%] xl:w-[30%]  flex flex-col gap-[10px] cursor-move z-[4] select-none items-center relative"
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                  onMouseEnter={() => handleShowItemIndex(index)}
                  onMouseLeave={() => handleShowItemIndex(index)}
                  data-index={index}
                >
                  {showItemIndex === index ? (
                    <div
                      className="w-full h-full absolute z-[3] top-0 left-0 p-[30px] bg-black bg-opacity-[.5]
                      flex justify-start  items-start"
                    >
                      <div className="w-[50px] h-[50px] bg-white rounded-[50%] flex flex-col items-center justify-center">
                        <p className="text-black font-semibold">{index + 1}</p>
                      </div>
                    </div>
                  ) : null}
                  <div className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none flex items-center gap-[7px]">
                    <button
                      className="relative top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none"
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <FaTrash />
                    </button>
                    <button
                      className="relative top-0 right-0 z-50 p-1 bg-white rounded-bl focus:outline-none"
                      type="button"
                      onClick={() => handleCropClick(index)}
                    >
                      <FaScissors />
                    </button>
                  </div>

                  <div className="w-full h-[200px]">
                    <img
                      loading="lazy"
                      alt={`image-${index}`}
                      src={file?.url}
                      className="w-full h-full object-contain"
                      onClick={() => handleCropClick(index)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${file.url}`;
                      }}
                    />
                  </div>
                  <div className="flex z-[55] flex-col w-full gap-[7px] text-[14px] bg-[#f5f5f5] p-[7px]">
                    <p>
                      {file?.url?.split("/")[file?.url?.split("/").length - 1]}
                    </p>
                    <textarea
                      value={file?.comment}
                      onChange={(e) =>
                        handleCommentChange(index, e.target.value)
                      }
                      placeholder="Add your comment here"
                      className="w-full p-2 border rounded"
                    />
                    {/* <button
                          className="cursor-pointer"
                          onClick={() => handleCropClick(index)}
                        >
                          Crop
                        </button> */}
                    {/* <button onClick={() => handleRemoveFile(index)}>
                          Remove
                        </button> */}
                  </div>
                  <div
                    className="absolute inset-0 z-40 transition-colors duration-300"
                    style={{
                      backgroundColor:
                        fileDropping == index && fileDragging != index
                          ? "rgba(173, 216, 230, 0.5)"
                          : null,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {output ? (
          <div className="w-full z-[52] h-full bg-black bg-opacity-[.5] p-[30px] absolute left-0 top-0 flex flex-col gap-[15px] justify-center items-center">
            <div className="w-[300px] h-[300px]">
              <img
                loading="lazy"
                src={output}
                alt="output"
                className="w-full h-full object-contain"
              />
            </div>
            <div className="w-full flex items-center justify-center gap-[12px]">
              <button
                onClick={() => {
                  handleCropSubmit();
                  setShowCropModal(false);
                }}
                className="px-[20px] py-[7px] rounded-[4px] bg-[#22d87c] text-white"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setOutput(null);
                }}
                className="px-[20px] py-[7px] rounded-[4px] bg-red-600 text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {showCropModal && selectedFileIndex !== null && (
        <div className="fixed top-0 left-0 z-[50] inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-[80%] flex flex-col items-center gap-[12px] p-4 rounded-[12px] shadow-lg">
            <h2 className="text-center text-xl ">Crop Image</h2>
            <ReactCrop
              src={uploadPhoto[selectedFileIndex]?.url}
              crop={crops[selectedFileIndex]}
              onChange={(_, percentCrop) => {
                const newCrops = [...crops];
                newCrops[selectedFileIndex] = percentCrop;
                setCrops(newCrops);
              }}
              onComplete={(e) => {
                const newCompleteCrops = [...completeCrops];
                if (e.height === 0 || e.width === 0) {
                  newCompleteCrops[selectedFileIndex] = {
                    x: 0,
                    y: 0,
                    height: heights[selectedFileIndex],
                    width: widths[selectedFileIndex],
                    unit: "px",
                  };
                } else {
                  newCompleteCrops[selectedFileIndex] = e;
                }
                setCompleteCrops(newCompleteCrops);
              }}
            >
              <div className="w-[300px] h-[300px]">
                <img
                  loading="lazy"
                  ref={(el) => (imgRefs.current[selectedFileIndex] = el)}
                  crossorigin="anonymous"
                  alt={`image-${selectedFileIndex}`}
                  src={
                    uploadPhoto[selectedFileIndex] ||
                    uploadPhoto[selectedFileIndex]?.url
                  }
                  style={{
                    transform: `scale(${scales[selectedFileIndex]}) rotate(${rotations[selectedFileIndex]}deg)`,
                  }}
                  onLoad={(e) => onImageLoad(e, selectedFileIndex)}
                  className="w-full h-full  object-contain"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${uploadPhoto[selectedFileIndex].url}`;
                  }}
                />
              </div>
            </ReactCrop>
            <div className="w-full flex flex-col sm:flex-row justify-end mt-4 gap-2">
              <button
                onClick={() => rotateRight(selectedFileIndex)}
                className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded"
              >
                Rotate Right
              </button>
              <button
                onClick={() => previewBlob(selectedFileIndex)}
                className="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded"
              >
                Crop
              </button>
              <button
                onClick={() => download(selectedFileIndex)}
                className="w-full sm:w-auto px-4 py-2 bg-yellow-500 text-white rounded"
              >
                Download
              </button>
              <button
                onClick={closeCropModal}
                className="w-full sm:w-auto px-4 py-2 bg-red-500 text-white rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="w-full flex flex-wrap justify-center items-center gap-[15px]">
        {/* {thumbnail?.map((item, index) => {
          return (
            <img loading="lazy"
              key={index}
              src={item?.thumbnail}
              alt="imgaf"
              className="w-[150px]"
            />
          );
        })} */}
      </div>

      <div className="w-full flex justify-center mt-[30px] items-center gap-[20px]">
        <button
          onClick={() => setTabs(6)}
          className="px-[20px] py-[12px] rounded-[7px] bg-[#22d87c] text-white"
        >
          Next
        </button>
      </div>

      {isFileUploading === true ? <Spinner /> : null}
    </div>
  );
};

export default Step3;
